<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {

  }
</script>Ï

<style lang="scss">
@import "@/assets/css/index.css";
@import "@/assets/css/style.css";
</style>
