export default {
    '1001': 'sobre nosotros',
    '1002': 'premios honoríficos',
    '1003': 'comerciantes de marcas cooperativas',
    '1004': 'presentación de la empresa',
    '1005': 'perfil de la empresa',
    // '1005':'Corporate Profile',
    '1006': 'fundada en diciembre de 2016, basada en una enorme red de servicios de carga compartida, con la Misión corporativa de "prolongar una vida mejor", la compañía se compromete a proporcionar a los usuarios servicios de carga de dispositivos móviles rápidos, convenientes y accesibles, y es el proveedor líder de servicios de banco de energía compartido en China. "',
    '1007': 'la compañía cuenta con financiación estratégica de instituciones de capital de riesgo conocidas como tencent, ant, suning, Jinshajiang venture capital y Sequoia capital, y ha sido seleccionada en la lista de empresas de unicornio de Hangzhou durante tres años consecutivos, ganando el título de "empresa nacional de alta tecnología" y siendo la unidad rectora de la Asociación China de Internet. "',
    '1008': 'para los diferentes escenarios de consumo de los usuarios, Xiaodian Technology organiza productos multiformes como gabinetes grandes, gabinetes pequeños y equipos de carga móvil en grandes centros comerciales urbanos, hoteles, cines, aeropuertos y estaciones de tren de alta velocidad, cadenas de marcas de catering y lugares de consumo como entretenimiento y ocio. En la actualidad, el Banco de energía compartido de Xiaodian Technology cubre más de 2.700 ciudades a nivel de condado o superior en todo el país, proporcionando servicios de carga compartidos prestados y devueltos a más de 450 millones de usuarios para disfrutar de una vida inteligente y de calidad. "',
    '1009': 'fundador',
    // '1010':'The Founder',
    '1010': 'presidente Tang yongbo',
    '1011': 'director de la Asociación China de Internet y miembro del Comité de la Conferencia Consultiva Política del pueblo chino del distrito Yuhang de hangzhou',
    '1012': 'fue Jefe de transacciones de vida local de Alibaba taobao, Jefe de Negocios de películas de taobao, fundador de taodian, Jefe de Negocios de alimentos de TaoBao y agricultura y características de TaoBao en China. "',
    '1013': 'instituciones de inversión de marca',
    '1014': 'proceso de desarrollo',
    '1015': 'presidente de Xiaodian technology',
    '1016': 'pequeño Banco de energía eléctrica',
    '1017': 'servicio estándar para escenarios de consumo de alta frecuencia',
    '1018': 'su nombre',
    '1019': 'número de teléfono móvil',
    '1020': 'área de representación intencional',
    '1021': 'presupuesto de inversión (1 - 50000 / 50000 / más de 100000)',
    '1022': 'si es una cooperación de tienda (sí / no)',
    '1023': 'nombre de la tienda',
    '1024': 'dirección específica de la tienda',
    '1025': 'franquicia por encargo',
    '1026': 'de lunes a domingo',
    '1027': 'unirse de inmediato para obtener el Estado de ganancias del proyecto',
    '1028': '* la franquicia es arriesgada y la inversión debe ser cautelosa',
    '1029': 'elige la pequeña electricidad',
    '1030': 'elija un socio comercial que cree una situación beneficiosa para todos a largo plazo',
    '1031': 'por favor, introduzca su nombre',
    '1032': 'por favor, introduzca el número de teléfono móvil',
    '1033': 'presupuesto de inversión (1 - 50000 / 50000 / más de 100000)',
    '1034': 'solicitar la cooperación de inmediato',
    '1035': 'cobertura de escena completa',
    '1036': '24 horas de difusión publicitaria artesiana',
    '1037': 'cobertura de recursos de publicidad eléctrica pequeña, como entretenimiento, viajes, círculos empresariales, ocio y negocios',
    '1038': 'ocupa la entrada de tráfico de terminales en línea y fuera de línea para satisfacer todo tipo de necesidades publicitarias',
    '1039': 'diversificación de contenidos',
    '1040': 'lanzamiento preciso de acuerdo con la escena y la audiencia del producto',
    '1041': '450 millones de pequeños usuarios de electricidad en todo el país, cubriendo 2.700 ciudades',
    '1042': 'penetrar en el principal grupo de consumidores',
    '1043': 'experimente el empoderamiento del tráfico',
    '1044': 'resolver los puntos dolorosos de los anunciantes',
    '1045': 'configuración flexible de lanzamiento combinado multiespacial',
    '1046': 'evitar la colocación inválida e integrar con precisión los recursos de información',
    '1047': 'ayudar a alcanzar los objetivos de negocio',
    '1048': 'solicitar cooperación',
    '1049': 'publicidad hasta',
    '1050': 'uso de electricidad pequeña = productos "invisibles" para plantar hierba',
    '1051': 'exposición de alta frecuencia, combinada con múltiples derechos de juego',
    '1052': 'impulsar la marca y promover la transformación',
    '1053': 'plan de publicidad personalizado',
    '1054': 'promover eficientemente varios objetivos de negocio',
    '1055': 'el efecto publicitario de visualización de datos es aceptable',
    '1056': 'impulsado por algoritmos y tecnologías, ayuda a los anunciantes a descubrir a las personas más valiosas',
    '1057': 'maximizar el valor de cada lanzamiento',
    "1058": "¡ las buenas noticias llegan con frecuencia! Xiaodian Technology ha sido reconocida como la primera empresa de unicornio aprobada en el distrito de yuhang, hangzhou.',",
    '1059': 'recientemente, la Oficina de economía e información del distrito de Yuhang de Hangzhou emitió el "anuncio sobre la lista propuesta de empresas de unicornio y cuasi - unicornio en el distrito de Yuhang en 2023". Hangzhou Xiaodian Technology co., Ltd. ha sido reconocida como una empresa cuasi - unicornio en el distrito de yuhang, lo que confirma plenamente el reconocimiento de los logros y perspectivas de desarrollo de Xiaodian por parte del Gobierno y los expertos de la industria.',
    "1060": "cuenta de suscripción de tecnología de tienda pequeña",
    "1061": "¡ buenas noticias! Xiaodian Technology ganó el título de especialización, refinamiento, especialización y nuevas pequeñas y medianas empresas en la provincia de zhejiang",
    '1062': 'recientemente, el Departamento Provincial de economía e informatización de Zhejiang anunció la "segunda lista de pequeñas y medianas empresas especializadas, refinadas, características y nuevas en la provincia de Zhejiang en 2023". Hangzhou Xiaodian Technology co., Ltd. ha sido reconocida como "una pequeña y mediana empresa especializada, refinada, característica y de nuevo tipo en la provincia de Zhejiang en 2023" debido a su fuerte capacidad de I + D E innovación, excelentes resultados de I + D tecnológica, capacidad de desarrollo eficiente y capacidad práctica de implementación comercial de Internet de las cosas, con el objetivo de alcanzar un nuevo nivel en el desarrollo de alta calidad de xiaodian.',
    '1063': 'cuenta de suscripción de tecnología de tienda pequeña',
    '1064': '¿ has probado el "pago con palma"? Otra tecnología negra aterrizó en Hangzhou ',
    '1065': 'a través de la tecnología de reconocimiento "palma + vena palmar", el proceso de préstamo se puede completar con solo agitar la mano. Después del pago móvil del teléfono móvil, apareció el pago facial, y ahora el pago de palma también está en línea. "',
    '1066': 'noticias de la marea',
    '1067': 'la fuerza de Xiaodian Technology ganó la "marca de crecimiento de alta calidad 2023" para proteger una vida mejor, ",',
    '1068': 'recientemente, inspur New Consumer anunció la lista de "marcas de crecimiento de alta calidad 2023". como la única marca seleccionada en el campo de la carga compartida, Xiaodian Technology está en la lista con líderes de varias industrias como mingchuangyou, NetEase yanxuan y babycare para proteger la buena vida de los consumidores. "',
    '1069': 'construcción del partido y almacenamiento de Energía • resistencia al futuro',
    '1070': '¡ las buenas noticias se transmiten con frecuencia! Xiaodian Technology fue reconocida como la primera empresa de unicornio aprobada en el distrito de Yuhang de Hangzhou ',
    '1071': '¡ buenas noticias! Xiaodian Technology fue galardonada con "pequeñas y medianas empresas especializadas en la provincia de zhejiang",',
    '1072': '¿ has probado el "pago con palma"? Otra tecnología negra aterrizó en Hangzhou ',
    '1073': 'la fuerza de Xiaodian Technology ganó la "marca de crecimiento de alta calidad 2023" para proteger una vida mejor',
    '1074': 'construcción del partido y almacenamiento de Energía • resistencia al futuro',
    '1075': 'informe todo',
    '1076': 'dinámica empresarial',
    '1077': 'informes de los medios',
    '1078': 'actividades de marca',
    '1079': 'el año del dragón comienza bien el consumo del Festival de primavera es "caliente y caliente", y la pequeña electricidad teje una red de servicios de intercambio de electricidad',
    '1080': 'la vitalidad del Festival de primavera en muchas ciudades clave del país es fuerte, la demanda de alimentos, alojamiento, compras y entretenimiento se libera centralmente, y el consumo es "caliente y caliente". con su gran número de puntos y cuota de mercado, Xiaodian Technology satisface la demanda de electricidad de los usuarios en cualquier momento y en cualquier lugar, ayudando a los turistas y ciudadanos a viajar con electricidad completa durante el Festival de primavera. "',
    '1081': 'el año del dragón comienza bien el consumo del Festival de primavera es "caliente y caliente", y la pequeña electricidad teje una red de servicios de intercambio de electricidad',
    '1082': 'el turismo cultural de Shanxi es muy popular durante las vacaciones del Festival de primavera, y la pequeña tecnología eléctrica proporciona ayuda eléctrica',
    '1083': 'el Banco de energía compartido, como una "estación de carga móvil" indispensable en el proceso de viaje turístico de las personas hoy en día, también se verifica la popularidad del turismo cultural de Shanxi durante el Festival de primavera en los datos de alquiler del Banco de energía compartido. "',
    '1084': 'shanxi Evening news',
    '1085': 'el turismo cultural de Shanxi es muy popular durante las vacaciones del Festival de primavera, y la pequeña tecnología eléctrica proporciona ayuda eléctrica',
    '1086': 'el uso aumentó un 60% interanual, y el Banco de energía compartido tropical de consumo de Hangzhou durante el Festival de primavera',
    '1087': 'durante el Festival de primavera, el calor del consumo de Hangzhou se verificó en los datos de alquiler de bancos de energía compartidos. según los grandes datos de xiaodian, el uso de bancos de energía compartidos en Hangzhou aumentó drásticamente, y el uso de bancos de energía compartidos en entornos como comida, hoteles, ocio y entretenimiento y compras fue mayor que en el mismo período del año pasado. "',
    '1088': 'el uso aumentó un 60% interanual, y el Banco de energía compartido tropical de consumo de Hangzhou durante el Festival de primavera',
    '1089': 'salida con electricidad completa y lucha contra el pico de nuevo! ¡ la ceremonia de 2024 de Xiaodian Technology se celebró con éxito!',
    '1090': '¡ longxing, el futuro! El 2 de febrero, la ceremonia anual de Ciencia y tecnología de Xiaodian 2024, con el tema "salida completa de la electricidad y lucha contra la cima", pequeños electricistas, socios e invitados de todo el país se reunieron para presenciar y revisar los fructíferos resultados de las pequeñas centrales eléctricas y mirar juntos hacia el nuevo viaje en 2024! ',
    '1091': 'salida con electricidad completa y lucha contra el pico de nuevo! ¡ la ceremonia de 2024 de Xiaodian Technology se celebró con éxito!',
    '1092': 'el servicio gana el futuro Xiaodian Technology ganó el Premio "mejor applet de contenido de herramientas del año"',
    '1093': 'recientemente, la primera Conferencia de innovación de aplicaciones de applets de toda la red de aigc se celebró en el Centro de exposiciones y el Centro de Convenciones de la zona nacional de demostración de innovación independiente de Zhongguancun en beijing, en la que se promulgó el Premio "mejor applet de contenido de herramientas del año", y Xiaodian Technology ganó conjuntamente premios con marcas conocidas como car house, Baidu netdisc y Jinshan documents. "',
    '1094': 'el servicio gana el futuro Xiaodian Technology ganó el Premio "mejor applet de contenido de herramientas del año"',
    'HeaderOneItem': 'Página de inicio',
    'HeaderTwoItem': 'Centro de productos',
    'HeaderThreeItem': 'Noticias de la compañía',
    'HeaderFourItem': 'Solicitud de cooperación',
    'HeaderFiveItem': 'Preguntas frecuentes',
    'HeaderSixItem': 'Comerciantes',
    'HeaderSevenItem': 'Cooperación publicitaria',
    'HeaderEightItem': 'Sobre nosotros',
    'HeaderNineItem': 'Únete a nosotros',
    'HeaderTenItem': 'Información de facturación',
    'FooterOneItem': 'Centro de productos',
    'FooterTwoItem': 'Centro de prensa',
    'FooterThreeItem': 'Información de facturación',
    'FooterFourItem': 'Solicitud de cooperación',
    'FooterFourItemOne': 'Comerciantes',
    'FooterFourItemTwo': 'Franquicia por encargo',
    'FooterFourItemThree': 'Publicidad',
    'FooterFiveItem': 'Sobre la pequeña electricidad',
    'FooterFiveItemOne': 'Sobre nosotros',
    'FooterFiveItemTwo': 'Premios honoríficos',
    'FooterFiveItemThree': 'Socios',
    'FooterFiveItemFour': 'Únete a nosotros',
    'FooterInfoText1': 'El banco de energía Keda está sujeto a la declaración de regulación legal:',
    'FooterInfoText2': 'Agencias regulatorias legales relevantes:',
    'FooterCopyright': 'Hangzhou youdian Technology co., Ltd. 2016 - 2019 @ derechos de autor todos Zhejiang ICP preparado no. 15027995 - 5',
    'FooterOperateOne': 'Buzón oficial',
    'FooterOperateTwo': 'Oficial WS',
    'FooterOperateThree': 'Telegrama oficial',
    'FooterMedia1': 'Weibo',
    'FooterMedia2': 'Cuenta pública de Wechat',
    'FooterMedia3': 'Alipay Life No.',
    'HomeOnePcTitle': 'Pequeña tecnología eléctrica, pionera en la carga compartida',
    'HomeOnePcText1': 'El negocio cubre 2.700 ciudades a nivel de condado o superior en todo el país.',
    'HomeOnePcText2': 'Un total de 450 millones de usuarios registrados',
    'HomeOneH5Btn': 'Unirse de inmediato para obtener la lista de ganancias',
    'HomeTwoPcBtn': 'El negocio de agencia de kmec',
    'HomeTwoText': 'Una variedad de políticas de apoyo para crear una situación beneficiosa para todos',
    'HomeThreePcBtn': 'Obtener ejemplos de publicidad',
    'HomeThreeTitle': 'Diversificación de contenidos Entrega precisa según el escenario y la audiencia del producto',
    'HomeThreeText1': '450 millones de usuarios de KMEC en todo el país, con cobertura en 2.700 ciudades, penetrando en los principales grupos de consumidores.',
    'HomeThreeText': 'Diseño comercial de toda la escena combinación espacial multidimensional',
    'HomeFourPcTitle': 'Energía móvil compartida KMEC',
    'HomeFourPcText1': 'Tres interfaces: android, Apple y tipo - C para satisfacer las necesidades de carga en todos los aspectos',
    'HomeFivePcTitle': 'Exhibición de modelos de venta caliente',
    'HomeFivePcText1': 'Satisfacer la demanda de diferentes escenarios de consumo, alta compatibilidad interior y exterior',
    'HomeSixPcTitle': 'OEscanear el Código con un solo clic para pedir prestado y devolver fácilmente',
    'HomeSevenPcTitle': 'Comerciantes de marcas cooperativas',
    'JoinTwoTitle1': 'Únete a nosotros',
    'JoinTwoBtn': 'Ver todos los puestos',
    'JoinTwoTitle2': 'Puestos de reclutamiento caliente',
    'JoinTwoText1': 'No importa de dónde vengas, cuáles sean tus estudios y tu experiencia laboral, si tienes un talento sobresaliente en tu propio campo, ',
    'JoinTwoText2': 'Bienvenido a unirnos para liderar el futuro de la carga compartida.',
    'JoinThreeText1': 'Declaraciones legales y regulatorias:',
    'JoinThreeText2': 'Nuestra aplicación de gestión es una plataforma de alquiler no abierta diseñada para brindar a los usuarios la oportunidad de obtener ingresos alquilando fuentes de alimentación móviles compartidas. Estamos comprometidos a crear un entorno seguro y de cumplimiento para que los socios puedan obtener ingresos a través de canales legales. Para lograr este ambicioso objetivo, la terminal de gestión de KEDA recluta inquilinos de las Américas y enfatiza que todos los usuarios deben cumplir estrictamente con nuestro acuerdo de uso y las regulaciones relacionadas.',
    'JoinThreeText3': 'Keda Shared Power Bank Co., Ltd. tiene oficinas en el Reino Unido y Hong Kong. De acuerdo con las regulaciones de Hong Kong y las regulaciones británicas, tenemos licencia para operar en Hong Kong y el Reino Unido y supervisar estrictamente nuestras actividades. Al mismo tiempo, también contamos con una licencia MSB de EE. UU. y cumplimos estrictamente con las regulaciones regulatorias del gobierno de EE. UU. para brindar a todos los usuarios un entorno legal y de ganancias que cumpla con las normas.',
    'JoinThreeText4': 'Para garantizar la seguridad de los fondos de los usuarios, KEDA ofrece a los usuarios fuertes medidas de protección financiera. Todas las transacciones financieras realizadas en nuestra plataforma estarán sujetas a una estricta supervisión y protección. Tomamos múltiples medidas de protección de seguridad para garantizar que los fondos de los usuarios estén completamente protegidos en todos los aspectos, como transferencias, retiros y ganancias.',
    'JoinThreeText5': 'Creemos firmemente que cada usuario debe disfrutar de nuestros servicios en un entorno justo y seguro. Por lo tanto, las políticas legales y regulatorias son una parte importante de nuestras operaciones.',
    'JoinThreeText6': 'Prometemos comprometernos a brindar servicios de la más alta calidad, con la actitud más profesional, para lograr métodos operativos diversificados e innovación continua. Este es un nuevo punto de partida para que KEDA expanda el territorio comercial de la economía colaborativa. ¡Esperamos su participación y apoyo, y trabajaremos con nosotros para crear un futuro compartido con bajas emisiones de carbono!',
    'JoinThreeText7': 'Autoridades legales y regulatorias relevantes',
    'JoinThreeText8': '· 《Regulaciones Comerciales Británicas》',
    'JoinThreeText9': '· 《Regulaciones de Supervisión Financiera Británica》',
    'JoinThreeText10': '· 《Ordenanza sobre registro de empresas de Hong Kong》',
    'JoinThreeText11': '· 《Ordenanza sobre sistemas de pagos y empresas de depósitos de Hong Kong》',
    'JoinThreeText12': '· 《Ley de Privacidad Financiera de EE.UU.》',
    'JoinThreeText13': '· 《Ley Estadounidense de Modernización Financiera》',
    'JoinThreeText14': '· 《Comisión Australiana de Valores e Inversiones》',
    'JoinThreeText15': '· 《Marco de Protección al Consumidor Financiero de Canadá》',
    'JoinThreeText16': 'Nuestra empresa recuerda solemnemente a todos los usuarios de KEDA que no inicien sesión ni visiten sitios web ilegales que imiten a KEDA para evitar ser engañados.',
    'JoinFiveTitle': 'Liderando el futuro de compartir energía móvil',
    'MerchantOneTitle': 'Servicios cooperativos',
    'MerchantTwoTitle': 'Los comerciantes se benefician',
    'MerchantTwoTitle1': 'Mejorar los servicios',
    'MerchantTwoText1': 'Se puede cargar escaneando el código,',
    'MerchantTwoText2': 'Resolver las necesidades de carga de los consumidores con alta calidad,',
    'MerchantTwoText3': 'Mejorar la experiencia y la calidad del Servicio de los comerciantes y mejorar la competitividad de los comerciantes',
    'MerchantTwoTitle2': 'Ahorro de costos',
    'MerchantTwoText4': 'Ahorrar el costo de consumo de los equipos de carga propios de los comerciantes,',
    'MerchantTwoText5': 'Autoservicio, sin necesidad de atención del empleado,',
    'MerchantTwoText6': 'Mejorar la eficiencia de los servicios de los comerciantes y reducir los costos laborales',
    'MerchantTwoTitle3': 'Valor publicitario',
    'MerchantTwoText7': 'Pequeños programas, wechat, Alipay drenan de varias maneras,',
    'MerchantTwoText8': 'La cuenta pública proporciona la ubicación del comerciante, aumenta la exposición del comerciante y el flujo de pasajeros de forma gratuita.',
    'MerchantTwoTitle4': 'Fácil de operar',
    'MerchantTwoText10': 'El proceso en línea guía el uso, los pasos son simples y fáciles de devolver',
    'MerchantTwoTitle5': 'Garantía de cooperación',
    'MerchantTwoText13': 'El equipo de servicio de la ciudad de mil personas,',
    'MerchantTwoText14': 'Servicio personal especial en línea y fuera de línea 7 * 24 horas, uso sin preocupaciones',
    'MerchantTwoTitle6': 'Escolta de Seguridad',
    'MerchantTwoText16': 'Los productos pasan la certificación autorizada nacional de ccc, CQC y así sucesivamente,',
    'MerchantTwoText17': 'Las baterías cumplen con gb31241 (normas de Seguridad de china),',
    'MerchantTwoText18': 'Todos los equipos compran grandes seguros de PICC',
    'MerchantThreeTitle': 'Proceso de cooperación',
    'MerchantThreeBtn': 'Solicitud de cooperación',
    'MerchantFourTitle': 'Escenarios aplicables',
    'MerchantFiveTitle': 'Cooperación de comerciantes',
    'MerchantFiveText': '¡¡ cooperación beneficiosa para todos, carga de electricidad pequeña, ilumina todo el país, en cualquier momento y en cualquier lugar, nunca se corta la electricidad!',
    'MerchantFiveBtn': 'Solicitar cooperación de inmediato',
    'MerchantPanel6-1': 'Aeropuerto',
    'MerchantPanel6-2': 'Cafetería',
    'MerchantPanel6-3': 'Barra',
    'MerchantPanel6-4': 'Tienda de conveniencia',
    'MerchantPanel6-5': 'Restaurante',
    'MerchantPanel6-6': 'Fuente de sabiduria ',
    'MerchantPanel6-7': ' Centro Comercial',
    'MerchantPanel6-8': 'Estación',
    'ProductOneTitle': 'Cargador compartido KMEC',
    'ProductTwoTitle': 'Ventajas del producto',
    'ProductThreeTitle1': 'Salida de tres líneas',
    'ProductThreeText1': 'Salida de tres en una línea, tres interfaces android, Apple y tipo - c, para satisfacer las necesidades de carga de una manera integral.',
    'ProductThreeTitle2': 'Prestado y devuelto',
    'ProductThreeText2': 'Insertar y devolver',
    'ProductThreeTitle3': 'Ver en tiempo real',
    'ProductThreeText3': 'Ver el tiempo de uso y el costo de uso en tiempo real',
    'ProductThreeTitle4': 'Seguro y confiable',
    'ProductThreeText4': 'Ha pasado la certificación nacional de ccc, CQC Safety Regulation y la certificación CE de la ue.',
    'ProductThreeTitle5': 'Corriente inteligente',
    'ProductThreeText5': 'La mcu identifica automáticamente el tipo de teléfono móvil y coincide de manera inteligente con la mejor corriente de carga.',
    'ProductThreeTitle6': 'Devolución en diferentes lugares',
    'ProductThreeText6': 'Apoyar la devolución en diferentes lugares sin problemas de carga de viajes de Negocios',
    'ProductFourTitle': 'Introducción de productos de escena completa',
    'ProductFourTitle1': 'Corriente inteligente',
    'ProductFourTitle2': 'Alarma de fuga de electricidad',
    'ProductFourTitle3': 'Gestión de la energía',
    'ProductFourTitle4': '7 protección pesada',
    'ProductFourTitle5': 'Monitoreo en segundo plano',
    'ProductFourTitle6': 'Reciclaje y desguace',
    'ProductFourTitle7': 'Alarma de humo',
    'ProductFiveTitle': 'Exhibición de patentes',
    'StoreTitle': 'Información de facturación',
    'StoreTableTh1': 'Nombre de la tienda',
    'StoreTableTh2': 'Dirección de la tienda',
    'StoreTableTh3': 'Facturación de la tienda',
    'AboutOneText1': 'Con el progreso de la tecnología electrónica global y la popularización y aplicación de dispositivos móviles electrónicos como tabletas y teléfonos inteligentes en todo el mundo, ha surgido la demanda de carga frecuente de productos electrónicos. En particular, los teléfonos inteligentes se han convertido en una herramienta de comunicación importante para el 95% de la población mundial. Aunque las tomas de corriente tradicionales no están en todas partes, las necesidades de carga en viajes o actividades al aire libre son una parte normal de la vida moderna, y keda Mobile Energy es una empresa de carga de alta tecnología centrada en resolver las necesidades de carga de viajes.',
    'AboutOneText2': 'KMEC es una empresa de alta tecnología centrada en resolver las necesidades de carga de viajes. Es una conveniente empresa de energía móvil que integra producción, arrendamiento y operación.',
    'AboutOneText3': 'En el modelo de economía colaborativa, los usuarios pueden alquilar equipos de energía móvil a través de aplicaciones móviles u otros métodos, y pagar según la duración del uso o la cantidad de carga.',
    'AboutOneText4': 'Este modelo permite que más personas compartan recursos, mejora la eficiencia en la utilización de los recursos y también brinda oportunidades comerciales y de ganancias a las empresas.',
    'AboutOneText5': 'Con la popularidad de los dispositivos móviles, la demanda de comodidad y sostenibilidad de las personas ha aumentado, y la industria de la energía móvil compartida tiene amplias perspectivas de mercado.',
    'AboutOneText6': 'El modelo de negocio de nuestra empresa es un modelo de negocio de cooperación compartida. Si se suscribe a la calificación de dividendo de nivel de cooperación correspondiente de nuestra empresa, puede obtener el derecho a compartir los ingresos del equipo durante tres años. KMEC opera completamente equipos compartidos y los instala en algunas tiendas, bares, supermercados, plazas y otros lugares con mucho tráfico en países y ciudades maduros. Cada vez que alguien usa el equipo compartido, automáticamente le cobrará al usuario una tarifa de alquiler.',
    'AboutOneText7': 'Con cero recursos, cero costos y cero inventario, KMEC permite a decenas de millones de personas iniciar un negocio juntas, compartir beneficios, compartir recursos, brindar un servicio integral y compartir el mundo.',
    'AgentH1Title': 'Preguntas frecuentes',
    'AgentCateTitle1': 'Cuestiones generales',
    'AgentCateTitle2': 'Cuestiones técnicas',
    'AgentCateTitle3': 'Gastos y pagos',
    'AgentCateTitle4': 'Cuestiones de cooperación',
    'AgentCate1QuestionTitle1': '¿¿ cómo funciona pedir prestada la fuente de alimentación móvil?',
    'AgentCate1QuestionText1': 'Es muy simple pedir prestado energía móvil. Escanee el Código qr, introduzca el método de pago requerido y luego desconecte la fuente de alimentación móvil. Después de cargar, puede devolver la fuente de alimentación móvil a cualquiera de nuestras estaciones. Para ello, basta con empujar la fuente de alimentación móvil de vuelta a la estación de trabajo.',
    'AgentCate1QuestionTitle2': '¿¿ cómo devolver la fuente de alimentación móvil?',
    'AgentCate1QuestionText2': 'Solo necesita devolver la fuente de alimentación móvil a cualquiera de nuestras estaciones para devolverla a esa estación. No necesita realizar ninguna otra operación. Su préstamo terminará automáticamente y solo se le cobrará durante el período en que preste energía móvil.',
    'AgentCate1QuestionTitle3': '¿¿ necesito solicitar un préstamo de energía móvil?',
    'AgentCate1QuestionText3': 'No. por velocidad y conveniencia, puede escanear el Código QR para obtener energía móvil sin necesidad de descargar la Aplicación.',
    'AgentCate1QuestionTitle4': '¿¿ este servicio está disponible las 24 horas del día?',
    'AgentCate1QuestionText4': 'Sí, los dispositivos de energía móvil están disponibles las 24 horas del día y se pueden alquilar las 24 horas del día, pero el tiempo de reparto de beneficios de los dispositivos de los socios es de lunes a viernes, y el tiempo de reparto de beneficios de las tiendas de los socios es de sábado y domingo.',
    'AgentCate2QuestionTitle1': '¿¿ para qué dispositivos puedo cargar con energía móvil?',
    'AgentCate2QuestionText1': 'La fuente de alimentación móvil proporciona un cable de carga para todos los teléfonos inteligentes y auriculares comunes. Con tres versiones, lightning, usb - C y micro usb, puede cargar fácilmente cualquier dispositivo de Apple y android.',
    'AgentCate2QuestionTitle2': '¿¿ la fuente de alimentación móvil incluye un cable de carga?',
    'AgentCate2QuestionText2': 'Sí, el Banco de energía proporciona cables de carga para todos los teléfonos inteligentes y auriculares comunes. Con lightning, usb - C y micro usb, puede cargar fácilmente cualquier dispositivo de Apple y android.',
    'AgentCate2QuestionTitle3': '¿¿ cuánto tiempo se tarda en cargar mi teléfono inteligente?',
    'AgentCate2QuestionText3': 'El tiempo de carga depende de sus respectivos teléfonos inteligentes. Normalmente, se puede cargar hasta alrededor del 80% en 20 minutos.',
    'AgentCate3QuestionTitle1': '¿¿ cuánto cuesta pedir prestada una fuente de alimentación portátil?',
    'AgentCate3QuestionText1': '¡Los primeros 5 minutos son gratuitos! Después de eso, la tarifa de media hora comienza en $0.30-3 (dependiendo de la ciudad). El período máximo de uso es de 3 días. Si no devuelve la fuente de alimentación móvil en 3 días, le cobraremos 30 dólares. La fuente de alimentación móvil le pertenece ahora.',
    'AgentCate3QuestionTitle2': '¿¿ qué métodos de pago puedo usar?',
    'AgentCate3QuestionText2': 'Actualmente ofrecemos los siguientes métodos de pago: visa, mastercard, American express, paypal, applepay y Google pay.',
    'AgentCate3QuestionTitle3': '¿¿ necesito pagar un depósito para alquilar una fuente de alimentación móvil en el Centro comercial?',
    'AgentCate3QuestionText3': 'No, no se necesita un depósito para alquilar una fuente de alimentación móvil, solo tiene que pagar la tarifa de uso de la fuente de alimentación móvil.',
    'AgentCate4QuestionTitle1': '¿¿Cuánto tiempo duran las ganancias después de mi compra?',
    'AgentCate4QuestionText1': 'Desde la fecha de compra del equipo, un total de tres años de período de participación efectiva en los beneficios del equipo.',
    'AgentCate4QuestionTitle2': '¿¿ por qué no hay ganancias los fines de semana?',
    'AgentCate4QuestionText2': 'Después de que la fuente de alimentación móvil se ponga en el mercado, necesita encontrar un lugar con gran tráfico para desplegarse. Sin embargo, los alquileres de las tiendas en estos lugares son caros y los alquileres de los equipos de fin de semana se asignan a los comerciantes cooperativos. Por lo tanto, las tiendas o locales con dispositivos de energía móvil también son uno de los socios de KEDA.',
    'AgentCate4QuestionTitle3': '¿¿ por qué se cobra una tarifa por retirar dinero?',
    'AgentCate4QuestionText3': 'Las tarifas incluyen: salarios laborales, mantenimiento de equipos, impuestos sobre beneficios operativos de empresas, etc.',
    'AgentCate4QuestionTitle4': '¿¿ qué deben hacer los socios cuando el mercado madure?',
    'AgentCate4QuestionText4': 'Cuando el mercado madure, nuestra empresa abrirá sucursales en la región, y el modelo de trabajo de los socios de la región cambiará de la cooperación en línea a la cooperación fuera de línea. Los principales agentes son responsables de los equipos de los socios en el mercado, y los socios continuarán ganando dinero a través de los equipos. ¡Además de generar ingresos para el equipo, los agentes también reciben dividendos del equipo de los miembros del equipo, ¡ por lo que su desarrollo actual sienta las bases para sus ingresos futuros!',
    'AgentCate4QuestionTitle5': '¿¿ cuál es el propósito de keda de reclutar socios en las américas?',
    'AgentCate4QuestionText5': 'Sobre la base del concepto de negocio de beneficio mutuo, beneficio mutuo y desarrollo común, la compañía trabajará con sus socios para mantener el conocimiento de la marca líder de la Universidad de Ciencia y tecnología en el campo de la energía móvil y estabilizar las operaciones del mercado. ¡¡ el plan es alcanzar una cuota de mercado del 30% en algunos países de América para 2028 y extender los dispositivos de energía móvil de hkust a todos los rincones del mercado estadounidense!'
}