import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('@/views/JoinView.vue')
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('@/views/MerchantView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/ProductView.vue')
  },
  {
    path: '/storeBillInfo',
    name: 'storeBillInfo',
    component: () => import('@/views/StoreBillInfo.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/agentRecruit',
    name: 'agentRecruit',
    component: () => import('@/views/AgentRecruit.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('@/views/Cooperation.vue')
  },
  {
    path: '/hot',
    name: 'hot',
    component: () => import('@/views/HotView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return {x: 0, y: 0}
    }
  }
})

export default router
