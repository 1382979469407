export default {
    '1001':'About Us',
    '1002':'Honorary Awards',
    '1003':'Cooperative brand merchants',
    '1004':'Company Introduction',
    '1005':'Company Introduction',
    // '1005':'Corporate Profile',
    '1006':'The company was established in December 2016, based on a vast network of shared charging services, with the corporate mission of "extending for a better life", committed to providing users with fast, convenient, and easily accessible mobile device charging services. It is a leading provider of shared power bank services in China.',
    '1007':'The company has strategic financing from well-known venture capital institutions including Tencent, Ant, Suning, Jinshajiang Venture Capital, Sequoia Capital, etc. It has been selected into the list of Hangzhou Unicorn Enterprises for three consecutive years, won the title of "National High tech Enterprise", and is the director unit of China Internet Association.',
    '1008':'Xiaodian Technology arranges various forms of products, such as large cabinets, small cabinets, and mobile charging devices, in large transportation hubs such as urban shopping centers, hotels, cinemas, airports, and high-speed rail stations, chain catering brands, and entertainment and leisure consumption places, targeting different consumer scenarios. At present, Xiaodian Technology s shared power bank covers more than 2700 cities at or above the county level in China, providing on-demand shared charging services for over 450 million users, and enjoying a high-quality life with intelligence.',
    '1009':'Founder',
    // '1010':'The Founder',
    '1010':'Chairman Tang Yongbo',
    '1011':'Director of China Internet Association, member of Yuhang District CPPCC Committee of Hangzhou City.',
    '1012':'Formerly served as the head of local life transactions on Alibaba s Taobao platform, the head of Taobaos film business, the founder of Taodian, and the head of Taobao Food, Taobao Agriculture, and Special China Business.',
    '1013':'Brand investment institutions',
    '1014':'development history',
    '1015':'Chairman of Xiaodian Technology',
    '1016':'KMEC RECHARGEABLE',
    '1017':'STANDARD SERVICE FOR HIGH-FREQUENCY CONSUMPTION SCENARIOS',
    '1018':'Your address',
    '1019':'Mobile phone number',
    '1020':'Intended agency area',
    '1021':'Investment budget (over 10000 to 50000/50000 to 100000/100000)',
    '1022':'Is it a store cooperation (Yes/No)',
    '1023':'Store Name',
    '1024':'Store specific address',
    '1025':'Agency franchise',
    '1026':'Monday to Sunday',
    '1027':'Join now to obtain the project profit and loss statement',
    '1028':'*Franchising carries risks and investment needs to be cautious',
    '1029':'Choose a small battery',
    '1030':'Choose long-term co creation and win-win business partners',
    '1031':'Please enter your address',
    '1032':'Please enter your phone number',
    '1033':'Investment budget (over 10000 to 50000/50000 to 100000/100000)',
    '1034':'Apply for cooperation immediately',
    '1035':'Full scene coverage',
    '1036':'24-hour advertising dissemination self circulation',
    '1037':'Coverage of small electronic advertising resources such as entertainment, travel, commercial districts, leisure, and business,',
    '1038':'Occupy the online and offline terminal traffic entrance to meet various advertising needs',
    '1039':'Content diversification',
    '1040':'Precise targeting based on the scene and product audience',
    '1041':'450 million small electricity users nationwide, covering 2700 cities,',
    '1042':'Infiltrate the main consumer group',
    '1043':'Experience traffic empowerment',
    '1044':'Addressing the pain points of advertisers',
    '1045':'Flexible configuration of multi space combination placement',
    '1046':'Avoiding ineffective advertising and precise integration of information resources，',
    '1047':'Assist in achieving business goals',
    '1048':'Applying for cooperation',
    '1049':'Advertising with high reach',
    '1050':'Using small electricity="invisible" product planting',
    '1051':'High frequency exposure, combined with various gameplay benefits，',
    '1052':'Boost brand capability and facilitate conversion',
    '1053':'Customized advertising placement plan',
    '1054':'Efficiently facilitate various business goals',
    '1055':'Data visualization advertising effect can be accepted',
    '1056':'Driven by algorithms and technology, helping advertisers discover the most valuable audience,',
    '1057':'Maximizing the value of each launch',
    '1058':'Good news keeps circulating! Xiaodian Technology has been recognized as the first approved unicorn enterprise in Yuhang District, Hangzhou City',
    '1059':'Recently, the Economic and Information Bureau of Yuhang District, Hangzhou City, released a public notice on the list of unicorn and quasi unicorn enterprises to be recognized in Yuhang District for 2023. Hangzhou Xiaodian Technology Co., Ltd. was recognized as a quasi unicorn enterprise in Yuhang District, fully confirming the recognition of the government and industry experts for the achievements and development prospects of Xiaodian.',
    '1060':'Xiaodian Technology Subscription Account',
    '1061':'Good news! Xiaodian Technology was awarded the title of "Zhejiang Province Specialized, Refined, Special, and New Small and Medium sized Enterprise"',
    '1062':'Recently, the Department of Economy and Information Technology of Zhejiang Province announced the list of "the second batch of specialized, refined, special, and new small and medium-sized enterprises in Zhejiang Province for 2023". Hangzhou Xiaodian Technology Co., Ltd. has been recognized as a "specialized, refined, special, and new small and medium-sized enterprise in Zhejiang Province for 2023" due to its strong research and development innovation capabilities, excellent technological research and development achievements, efficient development capabilities, and practical capabilities in commercial Internet of Things. This marks a new level of high-quality development for Xiaodian.',
    '1063':'Xiaodian Technology Subscription Account',
    '1064':'Have you tried "swipe payment"? Another Black Technology Landing in Hangzhou',
    '1065':'By using "palm print+palm vein" recognition technology, the rental process can be completed with just a wave of your hand. After mobile payment, facial recognition payment has emerged, and now palm recognition payment has also been launched.',
    '1066':'Tidal news',
    '1067':'Xiaodian Technology has won the title of "2023 High Quality Growth Brand" with its strength, safeguarding a better life',
    '1068':'Recently, Inspur New Consumer announced the list of "2023 High Quality Growth Brands". As the only brand selected in the field of shared charging, Xiaodian Technology, together with industry leaders such as Miniso, NetEase Yanxuan, and Babycare, has made the list to protect consumers beautiful lives.',
    '1069':'Party building energy storage • Endurance for the future',
    '1070':'Good news keeps circulating! Xiaodian Technology has been recognized as the first approved unicorn enterprise in Yuhang District, Hangzhou City',
    '1071':'Good news! Xiaodian Technology was awarded the title of "Zhejiang Province Specialized, Refined, Special, and New Small and Medium sized Enterprise"',
    '1072':'Have you tried "swipe payment"? Another Black Technology Landing in Hangzhou',
    '1073':'Xiaodian Technology has won the title of "2023 High Quality Growth Brand" with its strength, safeguarding a better life',
    '1074':'Party building energy storage • Endurance for the future',
    '1075':'All reports',
    '1076':'Enterprise dynamics',
    '1077':'media coverage ',
    '1078':'Brand activities',
    '1079':'The the Year of the Loong started well | Spring Festival consumption was "hot", and small electricity firmly knit the electricity sharing service network',
    '1080':'Multiple key cities across the country are experiencing strong and surging vitality during the Spring Festival, with a concentrated release of demand for food, accommodation, transportation, shopping, and entertainment. Consumption is "hot and hot", and Xiaodian Technology, with its large number of points and market share, meets the electricity needs of users anytime and anywhere. During the Spring Festival, it helps tourists and citizens travel with full electricity.',
    '1081':'The the Year of the Loong started well | Spring Festival consumption was "hot", and small electricity firmly knit the electricity sharing service network',
    '1082':'Shanxi s cultural tourism is very popular during the Spring Festival holiday, and Xiaodian Technology provides power assistance',
    '1083':'As an essential "mobile charging station" for people is travel nowadays, the popularity of Shanxi is cultural tourism during the Spring Festival has also been verified through the rental data of shared power banks',
    '1084':'Shanxi Evening News',
    '1085':'Shanxi is cultural tourism is very popular during the Spring Festival holiday, and Xiaodian Technology provides power assistance',
    '1086':'Usage increased by 60% year-on-year, and during the Spring Festival, Hangzhou is consumption of tropical shared power banks increased by 60%',
    '1087':'During the Spring Festival, the consumption heat of Hangzhou was verified on the rental data of the shared power bank. According to the big data of small TVs, the use of the shared power bank in Hangzhou has risen sharply, and the use of the shared power bank in the environment of food, hotels, leisure and entertainment, shopping, etc. is higher than that in the same period last year.',
    '1088':'Usage increased by 60% year-on-year, and during the Spring Festival, Hangzhou is consumption of tropical shared power banks increased by 60%',
    '1089':'Set off at full capacity, once again strive for the peak | The 2024 Xiaodian Technology Annual Ceremony was successfully held!',
    '1090':'Dragon walking, the future is bright! On February 2nd, the 2024 Xiaodian Technology Annual Ceremony, with the theme of "Starting from Full Charge, Fighting the Peak Again", gathered Xiaodian people, partners, and special guests from all over the country to witness and review the fruitful achievements of Xiaodian, and jointly look forward to a new journey in 2024!',
    '1091':'Set off at full capacity, once again strive for the peak | The 2024 Xiaodian Technology Annual Ceremony was successfully held!',
    '1092':'Service wins the future | Xiaodian Technology won the "Best Tool Content Mini Program of the Year" award',
    '1093':'Recently, the first AIGC Network wide Mini Program Application Innovation Conference was held at the Exhibition Center and Conference Center of the Zhongguancun National Independent Innovation Demonstration Zone in Beijing. At the conference, the "Best Tool Content Mini Program of the Year" award was announced, and Xiaodian Technology, along with well-known brands such as Autohome, Baidu Netdisk, and Kingsoft Document, jointly won the award.',
    '1094':'Service wins the future | Xiaodian Technology won the "Best Tool Content Mini Program of the Year" award',
    'HeaderOneItem': 'Home page',
    'HeaderTwoItem': 'Product Center',
    'HeaderThreeItem': 'Company News',
    'HeaderFourItem': 'Cooperation',
    'HeaderFiveItem': 'Agents',
    'HeaderSixItem': 'Merchant cooperation',
    'HeaderSevenItem': 'Advertising cooperation',
    'HeaderEightItem': 'About Us',
    'HeaderNineItem': 'Join us',
    'HeaderTenItem': 'billing information',
    'FooterOneItem': 'Product Center',
    'FooterTwoItem': 'News Center',
    'FooterThreeItem': 'billing information',
    'FooterFourItem': 'Cooperation application',
    'FooterFourItemOne': 'Merchant cooperation',
    'FooterFourItemTwo': 'Agent join',
    'FooterFourItemThree': 'ad serving',
    'FooterFiveItem': 'About Xiaodian',
    'FooterFiveItemOne': 'About Us',
    'FooterFiveItemTwo': 'Honorary Awards',
    'FooterFiveItemThree': 'Cooperation partners',
    'FooterFiveItemFour': 'Join us',
    'FooterInfoText1': 'Company address: No. 705, Building 5, Zhejiang Overseas High level Talent Innovation Park, No. 998 Wenyi West Road, Yuhang District, Hangzhou City',
    'FooterInfoText2': 'Consumer Services',
    'FooterInfoText3': 'Monday to Sunday',
    'FooterCopyright': 'Hangzhou Youdian Technology Co., Ltd. 2016-2019 @ Copyright Zhejiang ICPB 15027995-5',
    'FooterOperateOne': 'Co-Branding',
    'FooterOperateTwo': 'Media Liaison',
    'FooterOperateThree': 'Clean governance reporting',
    'FooterMedia1': 'MicroBlog',
    'FooterMedia2': 'WeChat official account',
    'FooterMedia3': 'Alipay life number',
    'HomeOnePcTitle': 'Xiaodian Technology, a pioneer in shared charging',
    'HomeOnePcText1': 'Business coverage in 2700 county-level and above cities across the country',
    'HomeOnePcText2': 'Accumulated registered users reached 450 million',
    'HomeOneH5Btn': 'Join now to obtain a profit list',
    'HomeTwoPcBtn': 'View details of Xiaodian\'s agent franchise business',
    'HomeThreePcBtn': 'Obtain advertising examples',
    'HomeFourPcTitle': 'Small electric shared power bank',
    'HomeFourPcText1': 'Android, Apple, and Type-C interfaces meet all charging needs in all aspects',
    'HomeFivePcTitle': 'Display of best-selling models',
    'HomeFivePcText1': 'Meet the needs of different consumer scenarios, with high indoor and outdoor compatibility',
    'HomeSixPcTitle': 'One click scan for easy borrowing and returning',
    'HomeSevenPcTitle': 'Cooperative brand merchants',
    'JoinTwoTitle1': 'Join Us',
    'JoinTwoBtn': 'View all jobs',
    'JoinTwoTitle2': 'HOT JOBS',
    'JoinTwoText1': 'NO MATTER WHERE YOU COME FROM, WHAT YOUR STUDY AND WORK EXPERIENCE IS, IF YOU HAVE OUTSTANDING TALENT IN YOUR OWN FIELD, YOU ARE WELCOME TO JOIN US TO LEAD THE FUTURE OF SHARED CHARGING.',
    'JoinTwoText2': 'JOIN US IN LEADING THE FUTURE OF SHARED CHARGING.',
    'JoinThreeText1': 'kmec technology, as a leading company in the field of shared charging in terms of capital, technology and operational and revenue capabilities, has a cumulative total of 450 million registered users.',
    'JoinThreeText2': 'Covering more than 2,700 cities nationwide, kmec continues to improve our operational efficiency by strengthening refined management and digitalization.',
    'JoinThreeText3': 'As a leading company in the field of shared charging, kmec Technology has a cumulative registered user base of 450 million, covering 2700 county-level and above cities nationwide. Xiaodian continues to improve our operational efficiency by strengthening refined management and digital construction.',
    'JoinFiveTitle': 'Leading the Future of Shared Charging',
    'MerchantOneTitle': 'COOPERATION SERVICES',
    'MerchantTwoTitle': 'MERCHANTS BENEFIT',
    'MerchantTwoTitle1': 'ENHANCED SERVICES',
    'MerchantTwoText1': 'CHARGING BY SCANNING THE CODE.',
    'MerchantTwoText2': 'HIGH-QUALITY SOLUTION TO CONSUMERS\' CHARGING NEEDS.',
    'MerchantTwoText3': 'ENHANCE THE SERVICE EXPERIENCE AND QUALITY OF MERCHANTS AND INCREASE THEIR COMPETITIVENESS.',
    'MerchantTwoTitle2': 'COST SAVING',
    'MerchantTwoText4': 'SAVING THE COST OF CHARGING EQUIPMENTS PROVIDED BY MERCHANTS.',
    'MerchantTwoText5': 'SELF-SERVICE, NO NEED TO BE SUPERVISED BY STORE STAFF.',
    'MerchantTwoText6': 'IMPROVE SERVICE EFFICIENCY AND REDUCE LABOR COSTS',
    'MerchantTwoTitle3': 'ADVERTISING VALUE',
    'MerchantTwoText7': 'FB, YOUTUBE, MULTIPLE WAYS TO ATTRACT TRAFFIC.',
    'MerchantTwoText8': 'PUBLIC NUMBER PROVIDES MERCHANTS WITH LOCATION, INCREASING EXPOSURE AND CUSTOMER TRAFFIC FOR FREE.',
    'MerchantTwoTitle4': 'EASY TO OPERATE',
    'MerchantTwoText10': 'THE ONLINE PROCESS GUIDES THE USE OF SIMPLE STEPS, EASY TO RETURN.',
    'MerchantTwoTitle5': 'COOPERATION GUARANTEE',
    'MerchantTwoText13': 'THOUSANDS OF CITY SERVICE TEAM.',
    'MerchantTwoText14': 'ONLINE AND OFFLINE 7*24 HOURS PERSONALIZED SERVICE, WORRY-FREE USE.',
    'MerchantTwoTitle6': 'SAFETY',
    'MerchantTwoText16': 'PRODUCTS HAVE PASSED NATIONAL CCC, CQC AND OTHER AUTHORITATIVE CERTIFICATION.',
    'MerchantTwoText17': 'All battery cells comply with GB31241 (Chinese safety regulations),',
    'MerchantTwoText18': 'All equipment is insured by People\'s Insurance of China (PICC) with a large amount of insurance',
    'MerchantThreeTitle': 'COOPERATION PROCESS',
    'MerchantThreeBtn': 'Applying for cooperation',
    'MerchantFourTitle': 'OFFLINE CASE SHOW',
    'MerchantFiveTitle': 'Merchant cooperation',
    'MerchantFiveText': 'Win win cooperation, small battery charging, lighting up the whole country, anytime, anywhere, never power off!',
    'MerchantFiveBtn': 'Apply for cooperation immediately',
    'ProductOneTitle': 'Small electric power bank',
    'ProductTwoTitle': 'PRODUCT ADVANTAGES',
    'ProductThreeTitle1': 'THREE-WIRE OUTPUT',
    'ProductThreeText1': 'THREE-IN-ONE LINE OUTPUT, ANDROID, APPLE, TYPE-C THREE INTERFACES, ALL-ROUND TO MEET THE CHARGING NEEDS.',
    'ProductThreeTitle2': 'BORROW-AND-RETURN',
    'ProductThreeText2': 'PLUG IN AND RETURN',
    'ProductThreeTitle3': 'REAL-TIME VIEW',
    'ProductThreeText3': 'REAL-TIME VIEW OF USAGE TIME AND COST',
    'ProductThreeTitle4': 'SAFE AND RELIABLE',
    'ProductThreeText4': 'PASSED THE NATIONAL CCC, CQC SAFETY CERTIFICATION, AS WELL AS THE EUROPEAN UNION CE AND OTHER CERTIFICATIONS.',
    'ProductThreeTitle5': 'INTELLIGENT CURRENT',
    'ProductThreeText5': 'MCU AUTOMATICALLY RECOGNIZES THE TYPE OF CELL PHONE AND INTELLIGENTLY MATCHES THE BEST CHARGING CURRENT',
    'ProductThreeTitle6': 'RETURN TO OTHER PLACES',
    'ProductThreeText6': 'NO NEED TO WORRY ABOUT CHARGING WHEN TRAVELING.',
    'ProductFourTitle': 'FULL SCENE PRODUCT INTRODUCTION',
    'ProductFourTitle1': 'INTELLIGENT CURRENT',
    'ProductFourTitle2': 'LEAKAGE ALARM',
    'ProductFourTitle3': 'POWER MANAGEMENT',
    'ProductFourTitle4': '7-PRONGED PROTECTION',
    'ProductFourTitle5': 'BACKSTAGE MONITORING',
    'ProductFourTitle6': 'RECYCLING',
    'ProductFourTitle7': 'SMOKE ALARM',
    'ProductFiveTitle': 'Patent display',
    'StoreTitle': 'billing information',
    'StoreTableTh1': 'Store Name',
    'StoreTableTh2': 'Store address',
    'StoreTableTh3': 'Store billing',
    'AboutOneText1': 'With the advancement of global electronic technology and the worldwide popularization and application of electronic mobile devices such as tablet computers and smartphones, the need for frequent charging of electronic products has emerged. In particular, smart phones have been the essential communication tool for 95 percent of the world\'s population. While traditional power outlets are not available everywhere, the need for charging during travel or outdoor activities is a normal part of modern life, Keda Mobile Energy is a high-tech charging company that focuses on solving the need for charging during travel. ',
    'AboutOneText2': 'KEDA is a high-tech company that focuses on solving the charging needs during traveling. It is a convenient mobile power company that integrates production, leasing and operation.',
    'AboutOneText3': 'Under the sharing economy model, users can rent mobile power devices through mobile apps or other means, and pay according to the length of use or the amount of charge. This model allows more people to share resources and improve the efficiency of resource utilization, while also bringing business and profit opportunities to enterprises.',
    'AboutOneText4': 'With the popularization of mobile devices and the increased demand for convenience and sustainability, the shared mobile power industry has a broad market prospect.',
    'AboutOneText5': 'Our company\'s business model is a hosting business model, you buy a universal mobile power device in our company, that is to say, you enjoy the right to share the income of this device for two years, our company will help you manage this device and install it in some stores, bars, supermarkets, squares and all other crowded places in other countries and cities. It will automatically charge you for every hour that people use your device. This is management income. No resources, no cost, no inventory.KEDA allows millions of people to start a business together, share benefits, share resources, provide one-stop service and share the world.',
}